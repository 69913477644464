import React from 'react';
import './App.css';
import './Data/Axios'
import 'react-toastify/dist/ReactToastify.css';
import {QueryClient, QueryClientProvider} from "react-query";
import {RouterProvider} from "react-router-dom";
import {AnimatePresence} from "framer-motion";
import {Provider} from "react-redux";
import {store} from "./Store/Store";
import {PersistGate} from "redux-persist/integration/react";
import {router} from "./Routing";
import {ChakraProvider} from "@chakra-ui/react";
import {theme} from "./Theme";
import {persistStore} from "redux-persist";

const persistor = persistStore(store);
const queryClient = new QueryClient();

function App() {
    return (
        <div className="App">
            <QueryClientProvider client={queryClient}>
                {/*Redux*/}
                <Provider store={store}>
                    {/*Redux*/}
                    <PersistGate persistor={persistor}>
                        <ChakraProvider theme={theme}>
                            <AnimatePresence mode="wait">
                                <RouterProvider router={router}/>
                            </AnimatePresence>
                        </ChakraProvider>
                    </PersistGate>
                </Provider>
            </QueryClientProvider>
        </div>
    );
}

export default App;
