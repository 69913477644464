"use client";
import {ItemTemplate} from "../../Client/Models";
import {
    Box,
    Button,
    Center,
    Divider,
    Flex,
    HStack,
    Input,
    NumberInput, NumberInputField,
    Spacer,
    Stack,
    Text,
    VStack
} from "@chakra-ui/react";
import Form from '@rjsf/chakra-ui';
import {RJSFSchema} from "@rjsf/utils";
import validator from '@rjsf/validator-ajv8';
import MonacoEditor from 'react-monaco-editor';
import {useCallback, useEffect, useState} from "react";
import {IoMdTrash} from "react-icons/io";
import {
    deleteTemplateApiAdminIdDelete,
    getGetApiAdminGetQueryKey,
    saveTemplateApiAdminSavePatch
} from "../../Client/default/default";
import {useNavigate} from "react-router-dom";
import {useQueryClient} from "react-query";
import {useAdminAuthentication} from "../Utils/Axios";
import ErrorBoundary from "../Utils/ErrorBoundary";
import {string} from "yup";
import {EmptyMessage} from "../Utils/EmptyMessage";


const defaultSchema: RJSFSchema = {
    title: "Product title",
    description: "Description of product customization",
    type: 'object',
    required: ['name'],
    properties: {
        name: {
            type: "string",
            title: "Name",
            default: ""
        },
    },
};

const defaultSchemaUI = {
    name: {
        "ui:autofocus": true,
        "ui:emptyValue": "",
        "ui:placeholder": "Placeholder for name...",
        "ui:enableMarkdownInDescription": true,
        "ui:description": "An optional description"
    },
};

const ItemTemplateEditor = (props: { template: ItemTemplate }) => {
    const navigate = useNavigate();
    const queryClient = useQueryClient();
    const config = useAdminAuthentication()

    if (props.template.formSchema === '') {
        props.template.formSchema = JSON.stringify(defaultSchema);
    }
    if (props.template.formSchemaUI === '') {
        props.template.formSchemaUI = JSON.stringify(defaultSchemaUI);
    }

    const [template, setTemplate] = useState<ItemTemplate>(props.template);

    const [valid, setValid] = useState<boolean>(true);
    const [schema, setSchema] = useState<any>(props.template.formSchema);
    const [editor, setEditor] = useState<any>(null);

    const [validUI, setValidUI] = useState<boolean>(true);
    const [schemaUI, setSchemaUI] = useState<any>(props.template.formSchemaUI);
    const [editorUI, setEditorUI] = useState<any>(null);

    const [count, setCount] = useState(0);

    useEffect(() => {
        const timer = setTimeout(() => setCount(count + 1), 100)

        if (editor) {
            editor.layout();
        }
        if (editorUI) {
            editorUI.layout();
        }

        return () => clearTimeout(timer)
    }, [count, editor, editorUI])

    const onChange = useCallback((json: string) => {
        setTemplate(prev => {
            prev.formSchema = json
            return prev
        })

        try {
            let newSchema: RJSFSchema = JSON.parse(json);

            setSchema(JSON.stringify(newSchema))
            setValid(true);
        } catch (e) {
            setValid(false);
        }
    }, [setTemplate])

    const onChangeUI = useCallback((json: string) => {
        setTemplate(prev => {
            prev.formSchemaUI = json
            return prev
        })

        try {
            let newSchema: RJSFSchema = JSON.parse(json);

            setSchemaUI(JSON.stringify(newSchema))
            setValidUI(true);
        } catch (e) {
            setValidUI(false);
        }
    }, [setTemplate])

    const formatJSON = (val: string) => {
        try {
            const res = JSON.parse(val);
            return JSON.stringify(res, null, 2)
        } catch {
            return val;
        }
    }

    const onEditorDidMount = (editor: any, monaco: any) => {
        setEditor(editor);
    }

    const onEditorDidMountUI = (editor: any, monaco: any) => {
        setEditorUI(editor);
    }

    const log = (type: string) => console.log.bind(console, type);

    return (
        <VStack h={'100%'}>
            <Box>
                <Input
                    value={template.name}
                    onChange={(e) => setTemplate({
                        ...template,
                        name: e.target.value
                    })}
                    placeholder='Item template name'
                    mb={3}/>
                <Input
                    value={template.itemId}
                    onChange={(e) => setTemplate({
                        ...template,
                        itemId: e.target.value
                    })}
                    placeholder='Item id (EAN)'
                    mb={3}/>
            </Box>
            <HStack w={'100%'} h={'50%'} textAlign={'left'}>
                <Box w={'50%'} h={'100%'}>
                    <MonacoEditor
                        language="json"
                        theme="vs-dark"
                        value={formatJSON(template.formSchema)}
                        options={{
                            selectOnLineNumbers: true
                        }}
                        onChange={onChange}
                        editorDidMount={onEditorDidMount}
                    />
                </Box>
                <Box w={'50%'} h={'100%'}>
                    <MonacoEditor
                        language="json"
                        theme="vs-dark"
                        value={formatJSON(template.formSchemaUI)}
                        options={{
                            selectOnLineNumbers: true
                        }}
                        onChange={onChangeUI}
                        editorDidMount={onEditorDidMountUI}
                    />
                </Box>
            </HStack>
            {valid && validUI &&
                <Box h={'50%'} w={'50%'} textAlign={'left'} borderWidth='1px' borderRadius='lg' p={5} overflowY={'auto'}>
                    <ErrorBoundary>
                        <Form
                            schema={JSON.parse(schema) as RJSFSchema}
                            uiSchema={JSON.parse(schemaUI) as RJSFSchema}
                            validator={validator}
                            onError={log('errors')}
                        />
                    </ErrorBoundary>
                </Box>
            }
            {(!valid || !validUI) &&
                <Box h={'50%'} w={'50%'} textAlign={'left'} borderWidth='1px' borderRadius='lg' p={5}>
                    <EmptyMessage text={"Cannot parse JSON"}/>
                </Box>
            }
            <HStack w={'100%'}>
                <Spacer/>
                <Button
                    onClick={() => {
                        deleteTemplateApiAdminIdDelete({
                            id: props.template._id!
                        }, config)
                            .then(r => queryClient.invalidateQueries(getGetApiAdminGetQueryKey()))
                            .then(r => navigate('/admin/editor'))
                    }}
                    colorScheme={'red'}
                    leftIcon={<IoMdTrash/>}
                >
                    Delete
                </Button>
                <Button
                    onClick={() => navigate('/admin/editor')}
                    colorScheme={'red'}
                    variant={'outline'}>
                    Cancel
                </Button>
                <Button
                    onClick={() => {
                        saveTemplateApiAdminSavePatch(
                            template,
                            {
                                template_id: template._id!
                            },
                            config
                        )
                            .then(r => queryClient.invalidateQueries(getGetApiAdminGetQueryKey()))
                            .then(r => navigate('/admin/editor'))
                    }}
                >
                    Save
                </Button>
            </HStack>
        </VStack>
    )
}


export {
    ItemTemplateEditor
}
