import {createSlice, PayloadAction} from "@reduxjs/toolkit";

interface AdminState {
    password: string
}

const initialState: AdminState = {
    password: ""
};

const adminSlice = createSlice({
    name: 'settings',
    initialState,
    reducers: {
        setAdminPassword: (state, action: PayloadAction<string>) => {
            return {
                ...state,
                password: action.payload
            }
        },
    },
})

export const {setAdminPassword} = adminSlice.actions

export default adminSlice.reducer
