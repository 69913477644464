import {Button, Center, Divider, Heading, HStack, Icon, Stack, Text, VStack} from "@chakra-ui/react";
import {useLocation, useNavigate} from "react-router-dom";
import {UserData, UserItemData} from "../../Client/Models";
import {useDataApiUserDataGet} from "../../Client/default/default";
import Loading from "../Utils/Loading";
import {EmptyMessage} from "../Utils/EmptyMessage";
import {IoIosCheckmarkCircle, IoIosWarning} from "react-icons/io";

const OrderItemRow = (props: { item: UserItemData }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const hasForm = props.item.formSchema !== "";
    const hasData = props.item.formData !== "";
    const state = location.state;
    const attention = hasForm && !hasData;

    return (
        <Button
            onClick={() => navigate('customize', {
                state: {
                    ...state,
                    userItem: props.item
                }
            })}
            leftIcon={attention ? <Icon boxSize={5} as={IoIosWarning} color='orange'/> : undefined}
            isDisabled={!hasForm}>
            <Text maxWidth={'70%'} isTruncated>
                {
                    props.item.productName
                }
            </Text>
        </Button>
    )
}

const OrderViewer = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const state = location.state;

    const loginState = state && state.loginData;

    const {isLoading, isError, data} = useDataApiUserDataGet(loginState,
        {
            query: {
                enabled: !!loginState
            }
        });

    if (!loginState) {
        navigate("/")
    }

    if (isLoading) {
        return <Loading/>
    }

    if (isError || !data) {
        return <EmptyMessage text={"Error fetching item templates"}/>
    }

    const userData: UserData = data.data;

    const attentionCount = userData.items.reduce((c, i) =>
        (i.formData === "" && i.formSchema !== "") ? c + 1 : c, 0)

    return (
        <Stack>
            <Heading size={'lg'}>
                {
                    `Order: ${userData.orderId}`
                }
            </Heading>
            <Heading size={'sm'}>
                {
                    new Date(userData.orderPlacedDateTime).toLocaleString()
                }
            </Heading>
            <Divider/>
            {attentionCount > 0 &&
                <Center m={4} alignItems={"center"}>
                    <HStack background={'azhora.500'} borderRadius={'full'} py={2} px={4}>
                        <Icon boxSize={5} as={IoIosWarning} color='orange'/>
                        <Text fontSize="2l">
                            {
                                `${attentionCount} ${attentionCount > 1 ? "items" : "item"} requires attention`
                            }
                        </Text>
                    </HStack>
                </Center>
            }
            {attentionCount <= 0 &&
                <Center m={4} alignItems={"center"}>
                    <VStack>
                        <HStack background={'azhora.500'} borderRadius={'full'} py={2} px={4}>
                            <Icon boxSize={5} as={IoIosCheckmarkCircle} color='green'/>
                            <Text fontSize="2l">
                                No items require attention
                            </Text>
                        </HStack>

                        <Text ml={2} fontSize="2l">
                            You can now close this window
                        </Text>
                    </VStack>
                </Center>
            }
            {
                userData.items.map((item, i) =>
                    <OrderItemRow key={i} item={item}/>
                )
            }
        </Stack>
    )
}

export {
    OrderViewer
}
