import {useLocation, useNavigate} from "react-router-dom";
import {getDataApiUserDataGetQueryKey, saveApiUserSavePut, useDataApiUserDataGet} from "../../Client/default/default";
import Loading from "../Utils/Loading";
import {EmptyMessage} from "../Utils/EmptyMessage";
import {Box, Text} from "@chakra-ui/react";
import {UserItemData} from "../../Client/Models";
import validator from "@rjsf/validator-ajv8";
import Form from "@rjsf/chakra-ui";
import {RJSFSchema} from "@rjsf/utils";
import {useCallback} from "react";
import {useQueryClient} from "react-query";

const OrderItemEditor = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const queryClient = useQueryClient();
    const state = location.state;

    const userLoginState = state && state.loginData;
    const userItemState = state && state.userItem;

    if (!userItemState) {
        navigate("/")
    }

    const userItem: UserItemData = userItemState;

    const onSubmit = useCallback((data: any, event: any) => {
        const formData = JSON.stringify(data.formData);

        let params = {
            ...userLoginState,
            order_item_id: userItem.orderItemId,
            index: userItem.index,
            form_data: formData,
        }
        saveApiUserSavePut(params)
            .then(r => queryClient.invalidateQueries(getDataApiUserDataGetQueryKey(userLoginState)))
            .then(r => navigate(-1))
    }, [navigate, queryClient, userItem.index, userItem.orderItemId, userLoginState]);

    return (
        <Box h={'100%%'} w={'100%%'} textAlign={'left'} p={5}>
            <Form
                onSubmit={onSubmit}
                schema={JSON.parse(userItem.formSchema) as RJSFSchema}
                uiSchema={JSON.parse(userItem.formSchemaUI)}
                validator={validator}
                formData={userItem.formData !== "" ? JSON.parse(userItem.formData) : undefined}
            />
        </Box>
    )
}

export {
    OrderItemEditor
}
