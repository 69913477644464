import {
    Center,
    chakra,
    FormControl,
    FormErrorMessage, Heading,
    Input,
    InputGroup,
    InputLeftElement,
    Stack, VStack, Box, Button
} from "@chakra-ui/react";
import * as Yup from 'yup';
import {yupResolver} from '@hookform/resolvers/yup';
import {useForm} from 'react-hook-form'
import {MdLock} from "react-icons/md";
import {toast} from "react-toastify";
import {loginApiAdminLoginPut} from "../../Client/default/default";
import {LoginApiAdminLoginPutParams} from "../../Client/Models";
import {useNavigate} from "react-router-dom";
import axios from "axios";
import {useAppDispatch, useAppSelector} from "../../Store/Hooks";
import {setAdminPassword} from "../../Store/Features/AdminReducer";
import {useCallback, useEffect} from "react";

const CMdLock = chakra(MdLock);

const AdminLogin = () => {
    const dispatch = useAppDispatch();
    const admin = useAppSelector((state) => state.admin);
    const navigate = useNavigate();
    const validationSchema = Yup.object().shape({
        password: Yup.string()
            .required('This field is required'),
    });

    const formOptions = {resolver: yupResolver(validationSchema)};
    const {
        handleSubmit,
        register,
        formState: {errors, isSubmitting},
    } = useForm<LoginApiAdminLoginPutParams>(formOptions)

    const handleLoginSubmit = useCallback(async (formData: LoginApiAdminLoginPutParams) => {
        const toastId = toast.loading("Logging in...")

        await loginApiAdminLoginPut(formData)
            .then((r) => {
                toast.update(toastId, { render: "Log in successful", type: "success", isLoading: false, autoClose: 2000 });
                dispatch(setAdminPassword(formData.password));
                navigate('/admin/editor');
            }).catch(r => {
                toast.update(toastId, { render: "Password incorrect", type: "error", isLoading: false, autoClose: 2000 });
                return Promise.resolve();
            });
    }, [dispatch, navigate])

    useEffect(() => {
        if (admin.password && admin.password !== "") {
            loginApiAdminLoginPut({
                password: admin.password
            })
                .then((r) => {
                    navigate('/admin/editor');
                }).catch(r => {
                    // ignore...
                    return Promise.resolve();
                });
        }
    }, [admin.password, navigate])


    return (
        <Center w={'100%'}>
            <Box w={'80%'}>
                <form onSubmit={handleSubmit(handleLoginSubmit)}>
                    <VStack>
                        <FormControl isInvalid={'password' in errors}>
                            <InputGroup>
                                <InputLeftElement
                                    pointerEvents="none"
                                    children={<CMdLock color="gray.300"/>}
                                />
                                <Input
                                    type="password"
                                    id='password'
                                    placeholder={'Admin password'}
                                    {...register('password')}
                                />
                            </InputGroup>
                            <FormErrorMessage>
                                {errors.password?.message}
                            </FormErrorMessage>
                        </FormControl>
                        <Button
                            isLoading={isSubmitting}
                            loadingText={'Logging in...'}
                            type="submit"
                            variant="solid"
                            width="full"
                        >
                            {'Log in'}
                        </Button>
                    </VStack>
                </form>
            </Box>
        </Center>
    )
}

export {
    AdminLogin
}
