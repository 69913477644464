import {
    baseTheme,
    extendTheme,
    withDefaultColorScheme
} from "@chakra-ui/react";


export const theme = extendTheme({
        styles: {
            global: {
                html: {
                    fontSize: `18px`
                }
            }
        },
        fonts: {
            heading: 'Roboto',
            body: 'Roboto',
        },
        colors: {
            azhora : {
                900: baseTheme.colors.teal["900"],
                800: baseTheme.colors.teal["800"],
                700: baseTheme.colors.teal["700"],
                600: baseTheme.colors.teal["600"],
                500: baseTheme.colors.teal["500"],
                400: baseTheme.colors.teal["400"],
                300: baseTheme.colors.teal["300"],
                200: baseTheme.colors.teal["200"],
                100: baseTheme.colors.teal["100"]
            }
        },
        config: {
            initialColorMode: 'dark',
            useSystemColorMode: false,
        },
    },
    withDefaultColorScheme({colorScheme: 'teal'})
)
