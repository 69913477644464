import {AxiosRequestConfig} from "axios";
import {useEffect, useState} from "react";
import {useAppSelector} from "../../Store/Hooks";

export const useAdminAuthentication = () => {
    const admin = useAppSelector((state) => state.admin);
    const [config, setConfig] = useState<AxiosRequestConfig>({
        headers: {
            authorization: admin.password
        },
    })

    useEffect(() => {
        setConfig({
            ...config,
            headers: {
                ...config.headers,
                authorization: admin.password
            },
        })
    }, [admin.password])

    return config;
}
