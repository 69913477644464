import {Button, Divider, Stack, Text} from "@chakra-ui/react";
import {
    createTemplateApiAdminCreatePut, getGetApiAdminGetQueryKey,
    useGetApiAdminGet
} from "../../Client/default/default";
import {EmptyMessage} from "../Utils/EmptyMessage";
import Loading from "../Utils/Loading";
import {useQueryClient} from "react-query";
import {ItemTemplate} from "../../Client/Models";
import {useLocation, useNavigate} from "react-router-dom";
import {ItemTemplateEditor} from "./ItemTemplateEditor";
import {DisplaySize} from "../Utils/DisplaySize";
import {useAppSelector} from "../../Store/Hooks";
import {useAdminAuthentication} from "../Utils/Axios";
import {toast} from "react-toastify";

const AdminEditor = () => {
    const navigate = useNavigate();
    const queryClient = useQueryClient();
    const location = useLocation();
    const config = useAdminAuthentication()

    const state = location.state;
    const itemTemplateId = state?.itemTemplateId;
    const hasItemTemplateId = !!itemTemplateId;

    const {isLoading, isError, data} = useGetApiAdminGet({
        axios: config
    });

    if (isLoading) {
        return <Loading/>
    }

    if (isError || !data) {
        return <EmptyMessage text={"Error fetching item templates"}/>
    }

    if (hasItemTemplateId) {
        const template = data.data.find((t: ItemTemplate) => t._id === itemTemplateId)!
        if (template) {
            return <ItemTemplateEditor template={template}/>
        } else {
            return <Loading/>
        }
    } else {
        const createTemplate = () => {
            createTemplateApiAdminCreatePut(config)
                .then(r => {
                        queryClient.invalidateQueries(getGetApiAdminGetQueryKey())
                        return r
                    }
                )
                .then(r => {
                        navigate('', {
                            state: {
                                size: DisplaySize.FULL_SCREEN,
                                itemTemplateId: r.data._id!
                            }
                        })
                    }
                )
                .catch(r =>
                    toast.error("Could not create template!")
                )
        }

        return (
            <Stack>
                {data.data.map((t: ItemTemplate, i) =>
                    <Button key={i} onClick={() => {
                        navigate('', {
                            state: {
                                size: DisplaySize.FULL_SCREEN,
                                itemTemplateId: t._id!
                            }
                        })
                    }}>
                        {t.name}
                    </Button>
                )
                }
                <Divider/>
                <Button
                    variant='outline'
                    onClick={createTemplate}
                >
                    Add item template
                </Button>
            </Stack>
        )
    }
}

export {
    AdminEditor
}
