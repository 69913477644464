import {configureStore} from '@reduxjs/toolkit'
import {persistCombineReducers} from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import adminReducer from "./Features/AdminReducer";

const persistConfig = {
    key: 'root',
    storage,
}

const rootReducer = persistCombineReducers(
    persistConfig,
    {
        admin: adminReducer,
    }
);

export const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
        serializableCheck: false
    }),
});

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
