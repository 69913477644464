import React, {useEffect, useState} from "react";
import {
    AbsoluteCenter,
    Box,
    Button,
    HStack, useColorMode,
} from "@chakra-ui/react";
import {Outlet, useLocation, useNavigate} from "react-router-dom";
import {BsArrowLeft} from "react-icons/bs";
import {AnimateMount} from "./Module/Utils/Animation";
import {ToastContainer} from "react-toastify";
import {DisplaySize} from "./Module/Utils/DisplaySize";

export const Layout = (props: any) => {
    const navigate = useNavigate();
    const {colorMode} = useColorMode()
    const location = useLocation();
    const [size, setSize] = useState<DisplaySize>(DisplaySize.NORMAL);
    const state = location.state;

    let finalSize: DisplaySize = size;
    const stateSize = state && state.size;
    if (stateSize && stateSize !== "") {
        finalSize = stateSize;
    }

    useEffect(() => {
        setSize(DisplaySize.NORMAL)
    }, [setSize])

    const width = (function () {
        switch (finalSize) {
            case "SMALL":
                return {base: '70%', xl: '25%', md: '40%'}
            case "NORMAL":
                return {base: '100%', xl: '40%', md: '60%'}
            case "FULL_SCREEN":
                return {base: '100%', xl: '100%', md: '100%'}
        }
    })();

    const maxH = (function () {
        switch (finalSize) {
            case "SMALL":
            case "NORMAL":
                return "75vh"
            case "FULL_SCREEN":
                return "100%"
        }
    })();

    const hideBack = location.pathname === "/admin" || location.pathname === "/"

    return (
        <Box
            minH={props.heightOverride ? props.heightOverride : "100vh"}
        >
            <ToastContainer
                theme={colorMode}
                position={'bottom-center'}
                autoClose={3000}
            />
            <AbsoluteCenter
                display='flex'
                flexDirection='column'
                overflow={'hidden'}
                p={10}
                h={finalSize === DisplaySize.FULL_SCREEN ? '100%' : ''}
                w={width}>
                <HStack py={3}>
                    {!hideBack &&
                        <Button
                            size={'sm'}
                            variant='outline'
                            aria-label='Back'
                            leftIcon={<BsArrowLeft/>}
                            onClick={() => navigate(-1)}
                        >
                            Back
                        </Button>
                    }
                </HStack>
                <AnimateMount>
                    <Box p={2}
                         bg='azhora.700'
                         borderRadius='lg'
                         boxShadow='lg'
                         h={'90%'}
                         minH={"100px"}>
                        <Box
                            maxH={maxH}
                            h={finalSize === DisplaySize.FULL_SCREEN ? '100%' : ''}
                            p={3}
                            overflowY={'auto'}
                        >
                            <Outlet/>
                        </Box>
                    </Box>
                </AnimateMount>
            </AbsoluteCenter>
        </Box>
    )
}
