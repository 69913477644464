import {Layout} from "./Layout";
import {createBrowserRouter} from "react-router-dom";
import {Login} from "./Module/User/Login";
import { OrderViewer } from "./Module/User/OrderViewer";
import {AdminLogin} from "./Module/Admin/AdminLogin";
import { AdminEditor } from "./Module/Admin/AdminEditor";
import {OrderItemEditor} from "./Module/User/OrderItemEditor";

const routes = [{
    element: <Layout/>,
    children: [
        {
            path: "/admin",
            children: [
                {
                    path: "",
                    element: <AdminLogin/>,
                },
                {
                    path: "editor",
                    element: <AdminEditor/>,
                },
            ]

        },
        {
            path: "/",
            element: <Login/>,
        },
        {
            path: "/order",
            children: [
                {
                    path: "",
                    element: <OrderViewer/>,
                },
                {
                    path: "customize",
                    element: <OrderItemEditor/>,
                },
            ]
        },
    ]
}];

const router = createBrowserRouter(routes);

export {
    routes,
    router
}
